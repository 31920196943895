import VERTEX_SOURCE from './ico.v.glsl';
import FRAGMENT_SOURCE from './ico.f.glsl';

declare global {
    interface Window {
        inverted: boolean,
    }
}

const BLACK = 0x0a / 0xff;
const WHITE = 1 - BLACK;

const VERTEX_POSITIONS: Array<Array<number>> = [
    [0.000000,-0.900000,0.000000],
    [0.651240,-0.402493,0.473148],
    [-0.248746,-0.402493,0.765576],
    [-0.804982,-0.402493,0.000000],
    [-0.248746,-0.402493,-0.765576],
    [0.651240,-0.402493,-0.473148],
    [0.248746,0.402493,0.765576],
    [-0.651240,0.402493,0.473148],
    [-0.651240,0.402493,-0.473148],
    [0.248746,0.402493,-0.765576],
    [0.804982,0.402493,0.000000],
    [0.000000,0.900000,0.000000],
    [0.000000,-0.867500,0.000000],
    [0.000000,-0.932500,0.000000],
    [0.627723,-0.387960,0.456062],
    [0.674757,-0.417027,0.490234],
    [-0.239764,-0.387960,0.737930],
    [-0.257729,-0.417027,0.793222],
    [-0.775914,-0.387960,0.000000],
    [-0.834051,-0.417027,0.000000],
    [-0.239764,-0.387960,-0.737930],
    [-0.257729,-0.417027,-0.793222],
    [0.627723,-0.387960,-0.456062],
    [0.674757,-0.417027,-0.490234],
    [0.239764,0.387960,0.737930],
    [0.257729,0.417027,0.793222],
    [-0.627723,0.387960,0.456062],
    [-0.674757,0.417027,0.490234],
    [-0.627723,0.387960,-0.456062],
    [-0.674757,0.417027,-0.490234],
    [0.239764,0.387960,-0.737930],
    [0.257729,0.417027,-0.793222],
    [0.775914,0.387960,0.000000],
    [0.834051,0.417027,0.000000],
    [0.000000,0.867500,0.000000],
    [0.000000,0.932500,0.000000],
    [0.015962,-0.860541,0.049124],
    [0.589723,-0.422223,0.465981],
    [-0.203191,-0.422223,0.723619],
    [0.625414,-0.422223,0.416857],
    [0.051653,-0.860540,0.000000],
    [0.625414,-0.422223,-0.416857],
    [-0.041788,-0.860541,0.030361],
    [-0.260940,-0.422223,0.704855],
    [-0.751001,-0.422223,0.030361],
    [-0.041788,-0.860541,-0.030361],
    [-0.751001,-0.422223,-0.030361],
    [-0.260940,-0.422223,-0.704855],
    [0.015962,-0.860541,-0.049124],
    [-0.203191,-0.422223,-0.723619],
    [0.589723,-0.422223,-0.465981],
    [0.657337,-0.370570,0.416857],
    [0.657337,-0.370570,-0.416857],
    [0.792788,0.338646,0.000000],
    [-0.193327,-0.370570,0.753979],
    [0.599587,-0.370570,0.496342],
    [0.244979,0.338647,0.753979],
    [-0.776827,-0.370570,0.049124],
    [-0.286767,-0.370570,0.723619],
    [-0.641375,0.338647,0.465981],
    [-0.286767,-0.370570,-0.723619],
    [-0.776827,-0.370570,-0.049124],
    [-0.641375,0.338647,-0.465981],
    [0.599587,-0.370570,-0.496342],
    [-0.193327,-0.370570,-0.753979],
    [0.244979,0.338647,-0.753979],
    [0.641375,-0.338647,0.465981],
    [0.776827,0.370570,0.049124],
    [0.286767,0.370570,0.723619],
    [-0.244979,-0.338647,0.753979],
    [0.193327,0.370570,0.753979],
    [-0.599587,0.370570,0.496342],
    [-0.792788,-0.338646,0.000000],
    [-0.657337,0.370570,0.416857],
    [-0.657337,0.370570,-0.416857],
    [-0.244979,-0.338647,-0.753979],
    [-0.599587,0.370570,-0.496342],
    [0.193327,0.370570,-0.753979],
    [0.641375,-0.338647,-0.465981],
    [0.286767,0.370570,-0.723619],
    [0.776827,0.370570,-0.049124],
    [0.260940,0.422223,0.704855],
    [0.751001,0.422223,0.030361],
    [0.041788,0.860541,0.030361],
    [-0.589723,0.422223,0.465981],
    [0.203191,0.422223,0.723619],
    [-0.015962,0.860541,0.049124],
    [-0.625414,0.422223,-0.416857],
    [-0.625414,0.422223,0.416857],
    [-0.051653,0.860540,0.000000],
    [0.203191,0.422223,-0.723619],
    [-0.589723,0.422223,-0.465981],
    [-0.015962,0.860541,-0.049124],
    [0.751001,0.422223,-0.030361],
    [0.260940,0.422223,-0.704855],
    [0.041788,0.860541,-0.030361],
];

const BG_INDICIES: Array<number> = [
    1,2,3,
    2,1,6,
    1,3,4,
    1,4,5,
    1,5,6,
    2,6,11,
    3,2,7,
    4,3,8,
    5,4,9,
    6,5,10,
    2,11,7,
    3,7,8,
    4,8,9,
    5,9,10,
    6,10,11,
    7,11,12,
    8,7,12,
    9,8,12,
    10,9,12,
    11,10,12,
];

const FG_INDICIES: Array<number> = [
    38,13,37,
    37,16,38,
    39,15,38,
    38,18,39,
    37,17,39,
    39,14,37,
    41,15,40,
    41,16,14,
    41,23,13,
    41,24,42,
    40,23,42,
    42,16,40,
    44,13,43,
    44,14,18,
    45,17,44,
    45,18,20,
    45,13,19,
    45,14,43,
    47,13,46,
    47,14,20,
    47,21,19,
    47,22,48,
    48,13,21,
    48,14,46,
    49,21,13,
    50,14,22,
    50,23,21,
    51,22,24,
    51,13,23,
    51,14,49,
    53,15,52,
    52,24,53,
    54,23,53,
    54,24,34,
    54,15,33,
    54,16,52,
    56,17,55,
    56,18,16,
    56,25,15,
    57,16,26,
    57,17,25,
    57,18,55,
    59,19,58,
    59,20,18,
    59,27,17,
    60,18,28,
    60,19,27,
    60,20,58,
    61,19,21,
    61,20,62,
    63,19,62,
    63,20,30,
    61,29,63,
    63,22,61,
    64,21,23,
    64,22,65,
    66,21,65,
    66,22,32,
    64,31,66,
    66,24,64,
    67,33,15,
    67,34,68,
    69,33,68,
    69,34,26,
    69,15,25,
    67,26,16,
    70,25,17,
    70,26,71,
    72,25,71,
    72,26,28,
    72,17,27,
    70,28,18,
    73,27,19,
    73,28,74,
    75,27,74,
    74,30,75,
    73,29,75,
    73,30,20,
    77,21,76,
    76,30,77,
    77,31,29,
    77,32,78,
    76,31,78,
    76,32,22,
    80,23,79,
    79,32,80,
    80,33,31,
    80,34,81,
    79,33,81,
    79,34,24,
    83,25,82,
    83,26,34,
    83,35,33,
    83,36,84,
    82,35,84,
    82,36,26,
    86,27,85,
    85,26,86,
    87,25,86,
    86,36,87,
    85,35,87,
    87,28,85,
    89,29,88,
    88,28,89,
    90,27,89,
    90,28,36,
    90,29,35,
    90,30,88,
    91,29,31,
    92,32,30,
    92,35,29,
    92,36,93,
    93,31,35,
    91,36,32,
    94,31,33,
    94,32,95,
    95,35,31,
    95,36,96,
    94,35,96,
    94,36,34,
    38,15,13,
    37,14,16,
    39,17,15,
    38,16,18,
    37,13,17,
    39,18,14,
    41,13,15,
    41,40,16,
    41,42,23,
    41,14,24,
    40,15,23,
    42,24,16,
    44,17,13,
    44,43,14,
    45,19,17,
    45,44,18,
    45,43,13,
    45,20,14,
    47,19,13,
    47,46,14,
    47,48,21,
    47,20,22,
    48,46,13,
    48,22,14,
    49,50,21,
    50,49,14,
    50,51,23,
    51,50,22,
    51,49,13,
    51,24,14,
    53,23,15,
    52,16,24,
    54,33,23,
    54,53,24,
    54,52,15,
    54,34,16,
    56,15,17,
    56,55,18,
    56,57,25,
    57,56,16,
    57,55,17,
    57,26,18,
    59,17,19,
    59,58,20,
    59,60,27,
    60,59,18,
    60,58,19,
    60,28,20,
    61,62,19,
    61,22,20,
    63,29,19,
    63,62,20,
    61,21,29,
    63,30,22,
    64,65,21,
    64,24,22,
    66,31,21,
    66,65,22,
    64,23,31,
    66,32,24,
    67,68,33,
    67,16,34,
    69,25,33,
    69,68,34,
    69,67,15,
    67,69,26,
    70,71,25,
    70,18,26,
    72,27,25,
    72,71,26,
    72,70,17,
    70,72,28,
    73,74,27,
    73,20,28,
    75,29,27,
    74,28,30,
    73,19,29,
    73,75,30,
    77,29,21,
    76,22,30,
    77,78,31,
    77,30,32,
    76,21,31,
    76,78,32,
    80,31,23,
    79,24,32,
    80,81,33,
    80,32,34,
    79,23,33,
    79,81,34,
    83,33,25,
    83,82,26,
    83,84,35,
    83,34,36,
    82,25,35,
    82,84,36,
    86,25,27,
    85,28,26,
    87,35,25,
    86,26,36,
    85,27,35,
    87,36,28,
    89,27,29,
    88,30,28,
    90,35,27,
    90,89,28,
    90,88,29,
    90,36,30,
    91,92,29,
    92,91,32,
    92,93,35,
    92,30,36,
    93,91,31,
    91,93,36,
    94,95,31,
    94,34,32,
    95,96,35,
    95,32,36,
    94,33,35,
    94,96,36,
];

function icoVertexBuffer(): Float32Array {
    const startBg = 0;
    const endBg = Math.max(...BG_INDICIES) - 1;
    const startFg = Math.min(...FG_INDICIES) - 1;
    const endFg = VERTEX_POSITIONS.length - 1;

    if (endBg + 1 != startFg) {
        throw Error('indicies not well divided');
    }

    let arr = new Float32Array(VERTEX_POSITIONS.length * 4);
    let pos = startBg;

    while (pos <= endBg) {
        arr.set([...VERTEX_POSITIONS[pos], WHITE], pos * 4);
        pos += 1;
    }

    while (pos <= endFg) {
        arr.set([...VERTEX_POSITIONS[pos], BLACK], pos * 4);
        pos += 1;
    }

    return arr;
}

function icoIndexBuffer(): Int16Array {
    let arr = new Int16Array(BG_INDICIES.length + FG_INDICIES.length);
    arr.set(BG_INDICIES, 0);
    arr.set(FG_INDICIES, BG_INDICIES.length);
    for (const ind in arr) {
        arr[ind] -= 1;
    }

    return arr;
}

class IcoRender {
    private program: WebGLProgram;
    private vao: WebGLVertexArrayObject;
    private mtxarr: Float32Array = new Float32Array(16);
    private startTime: Date = new Date();
    private intervalId: number | null = null;
    private matrixUniform: WebGLUniformLocation;
    private invertedUniform: WebGLUniformLocation;
    private indexCount: number = 0;

    constructor(private gl: WebGL2RenderingContext) {
        // ============
        // Init Program
        // ============
        var vertexShader = this.compileShader(VERTEX_SOURCE, this.gl.VERTEX_SHADER);
        var fragmentShader = this.compileShader(FRAGMENT_SOURCE, this.gl.FRAGMENT_SHADER);

        this.program = this.gl.createProgram()!;
        this.gl.attachShader(this.program, vertexShader);
        this.gl.attachShader(this.program, fragmentShader);
        this.gl.linkProgram(this.program);

        var success = this.gl.getProgramParameter(this.program, this.gl.LINK_STATUS);
        if (success) {
            this.matrixUniform = this.gl.getUniformLocation(this.program, 'u_matrix')!;
            this.invertedUniform = this.gl.getUniformLocation(this.program, 'u_inverted')!;
        } else {
            throw new Error(this.gl.getProgramInfoLog(this.program) || 'unknown program error');
        }


        // ============
        // Init Program
        // ============
        this.vao = this.gl.createVertexArray()!;
        this.gl.bindVertexArray(this.vao);

        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.gl.createBuffer());
        this.gl.bufferData(this.gl.ARRAY_BUFFER, icoVertexBuffer(), this.gl.STATIC_DRAW);
        const loc = this.gl.getAttribLocation(this.program, 'a_data')
        this.gl.vertexAttribPointer(loc, 4, this.gl.FLOAT, false, 0, 0);
        this.gl.enableVertexAttribArray(loc);

        this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.gl.createBuffer());
        const indicies = icoIndexBuffer();
        this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, indicies, this.gl.STATIC_DRAW);
        this.indexCount = indicies.length;
    }

    private compileShader(source: string, type: number): WebGLShader {
        var shader = this.gl.createShader(type)!;
        this.gl.shaderSource(shader, source);
        this.gl.compileShader(shader);

        var success = this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS);
        if (success) {
          return shader;
        }

        console.log(source, type);
        throw new Error(this.gl.getShaderInfoLog(shader) || 'unknown shader error');
    }

    private drawFrame() {
        const seconds = (new Date().getTime() - this.startTime.getTime()) / 1e3;
        matrixFromAnimation(this.mtxarr, seconds);

        this.gl.clearColor(0.0, 0.0, 0.0, 0.0);
        this.gl.clearDepth(1);
        this.gl.enable(this.gl.DEPTH_TEST);

        this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);

        this.gl.useProgram(this.program);
        this.gl.bindVertexArray(this.vao);
        this.gl.uniformMatrix4fv(this.matrixUniform, false, this.mtxarr);
        this.gl.uniform1i(this.invertedUniform, window.inverted ? 1 : 0);
        this.gl.drawElements(this.gl.TRIANGLES, this.indexCount, this.gl.UNSIGNED_SHORT, 0);
    }

    public start() {
        if (this.intervalId == null) {
            this.intervalId = window.setInterval(() => {
                window.requestAnimationFrame(() => this.drawFrame());
            }, 1000 / 30);
        }
    }

    public stop() {
        if (this.intervalId != null) {
            window.clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }
}

function matrixFromRotation(out: Float32Array, rad: number, axis: ArrayLike<number>) {
    let x = axis[0], y = axis[1], z = axis[2];
    let len = Math.sqrt(x * x + y * y + z * z);

    // identity
    if (len < 0.000001) {
        out.fill(0);
        out[0] = 1;
        out[5] = 1;
        out[10] = 1;
        out[15] = 1;
        return
    }

    let s, c, t;
    len = 1 / len;
    x *= len;
    y *= len;
    z *= len;

    s = Math.sin(rad);
    c = Math.cos(rad);

    t = 1 - c;

    // Perform rotation-specific matrix multiplication
    out[0] = x * x * t + c;
    out[1] = y * x * t + z * s;
    out[2] = z * x * t - y * s;
    out[3] = 0;
    out[4] = x * y * t - z * s;
    out[5] = y * y * t + c;
    out[6] = z * y * t + x * s;
    out[7] = 0;
    out[8] = x * z * t + y * s;
    out[9] = y * z * t - x * s;
    out[10] = z * z * t + c;
    out[11] = 0;
    out[12] = 0;
    out[13] = 0;
    out[14] = 0;
    out[15] = 1;
}

function matrixFromAnimation(mtx: Float32Array, time: number) {
    matrixFromRotation(mtx, Math.PI / 2, [Math.sin(time + 1), Math.sin(time + 3), Math.sin(time + 6)]);
}

window.addEventListener('load', () => {
    const canvas = document.getElementById('ico-canvas') as HTMLCanvasElement;
    if (!canvas) { return }
    const pxratio = window.devicePixelRatio;
    const res = pxratio * 64;
    canvas.width = res;
    canvas.height = res;
    canvas.style.transform = `scale(${1 / pxratio})`;
    const ctx = canvas.getContext('webgl2')
    if (!ctx) { return }
    const render = new IcoRender(ctx);
    render.start();
});
