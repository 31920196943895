#version 300 es

precision mediump float;

in float v_color;
out vec4 f_color;
uniform bool u_inverted;

void main() {
    float color = v_color;
    if (u_inverted) color = 1.0 - v_color;
    f_color = vec4(color, color, color, 1);
}
