#version 300 es

in vec4 a_data;
out float v_color;
uniform mat4 u_matrix;

void main() {
   gl_Position = u_matrix * vec4(a_data.xyz, 1.);
   v_color = a_data.w;
}
